import React, { memo } from "react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
// hook
import { useTranslation, Trans } from "react-i18next";
import { Typography, Button } from "@material-tailwind/react";
import YogaTestimonial from "../testimonials/YogaTestimonial";
const YogaStretching = memo(({ open, close, setOpen }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <TEModal show={open} setShow={setOpen} scrollable>
        <TEModalDialog size="xl">
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5
                className={` leading-normal text-neutral-800 dark:text-neutral-200
             ${
               i18n.resolvedLanguage === "zh"
                 ? "font-reborn ml-3 text-2xl"
                 : "text-xl font-medium"
             }
              `}
              >
                {t("popUp.yoga.title")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => close(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            {/* <TEModalBody style={{ minHeight: "1500px" }}> */}

            <TEModalBody
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <div className="popContainer">
                <div className="mb-4 flex justify-center">
                  <img
                    src="/images/service/yoga.jpg"
                    className="w-[1500] h-[350] rounded-lg mt-4"
                    alt=""
                  />
                </div>

                <p className="mb-3">{t("popUp.yoga.p1")}</p>

                <ul>
                  <li key="y2">{t("popUp.yoga.p2")}</li>
                  <li key="y3">{t("popUp.yoga.p3")}</li>
                </ul>

                <YogaTestimonial />
              </div>
            </TEModalBody>
            <TEModalFooter
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <TERipple rippleColor="light">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border  px-4 rounded font-reborn"
                  onClick={() => close(false)}
                >
                  <Typography className="text-gray-600 font-reborn text-sm">
                    {t("popUp.counseling.close")}
                  </Typography>
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
});

export default YogaStretching;
