import React, { useEffect, useState } from "react";

// Tailwind

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";

import { useLocation } from "react-router-dom";
import { Element, animateScroll as scroll } from "react-scroll";
// Get posts from Ghost
import { getPosts } from "../ghost/post";

// hook
import { useTranslation } from "react-i18next";
export default function Blogs() {
  const [ghostPosts, setGhostPosts] = useState([]);

  const { t, i18n } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedPosts = await getPosts();
      setGhostPosts(fetchedPosts);
    };
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      <section className="relative md:py-16 py-16 bg-green-400" id="blog">
        <Element id="blog" />
        <div className="brush_news_2" data-wow-duration="1s">
          <img src="/images/bg/brushes/news/2.png" alt="1" />
        </div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3
              className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
                i18n.resolvedLanguage === "zh"
                  ? "font-reborn  "
                  : "font-semibold"
              } `}
            >
              {t("blog.title")}
            </h3>
          </div>
          <div>
            <section className="dark:bg-gray-800 dark:text-gray-100">
              <div className="  container max-w-6xl p-6 mx-auto space-y-6 sm:space-y-12">
                <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3">
                  {ghostPosts.map((post, index) => (
                    <Card className=" w-4/5" key={index}>
                      <CardHeader color="blue-gray" className="relative h-56 ">
                        <img
                          src={post.feature_image}
                          alt="card-image"
                          className="mt-3 object-cover w-full rounded h-44 dark:bg-gray-500"
                          style={{ width: 400, height: 250 }}
                        />
                      </CardHeader>
                      <CardBody>
                        <h3
                          className={`text-2xl mb-6 ${
                            i18n.resolvedLanguage === "zh"
                              ? "font-reborn  "
                              : "font-semibold"
                          } `}
                        >
                          {post.title}
                          {index === 0 && (
                            <span
                              className={`bg-blue-100 text-amber-500  font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3 
                                ${
                                  i18n.resolvedLanguage === "zh"
                                    ? "font-reborn text-lg "
                                    : "text-sm"
                                } 
                                
                                `}
                            >
                              {t("blog.latest")}
                            </span>
                          )}
                        </h3>

                        <p
                          className={`${
                            i18n.resolvedLanguage === "zh"
                              ? "font-reborn font-semibold text-sm "
                              : "font-semibold"
                          } `}
                        >
                          {post.excerpt ? post.excerpt : ""}
                        </p>
                      </CardBody>
                      <CardFooter className="pt-0 mb-3">
                        <a
                          href={post.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className={`inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                              ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn  "
                                  : ""
                              }
                              `}
                          >
                            <strong> {t("blog.read-more")}</strong>
                          </Button>
                        </a>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              </div>
            </section>

            <div className="flex justify-center mt-6">
              <a
                href={process.env.REACT_APP_BLOG_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className={`inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                ${i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""}
                `}
                >
                  <strong> {t("blog.more-posts")}</strong>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
