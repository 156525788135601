import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import * as Unicons from "@iconscout/react-unicons";
import { Typography } from "@material-tailwind/react";

import {
  Zentangle,
  Counseling,
  PremaritalCounseling,
  YogaStretching,
  SFT,
} from "./popup";
// Import Carousel CSS
import "react-responsive-carousel/lib/styles/carousel.min.css";

// hook
import { useTranslation } from "react-i18next";

import { Carousel } from "react-responsive-carousel";
export default function Services() {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [counselingPopup, setCounselingPopup] = useState(false);
  const [premaritalCounselingPopup, setPremaritalCounselingPopup] =
    useState(false);
  const [yogaStretchingPopup, setYogaStretchingPopup] = useState(false);
  const [ZentanglePopup, setZentanglePopup] = useState(false);
  const [sftPopup, setSftPopup] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const servicesData = [
    {
      title: t("offer.list.1.title"),
      desc: t("offer.list.1.desc"),
      value: "Counseling",
      Icon: Icon.Airplay,
      image: "/images/service_icon/1.jpg",
    },
    {
      title: t("offer.list.2.title"),
      desc: t("offer.list.2.desc"),
      value: "Premarital or Postmarital Counseling",
      Icon: Icon.Aperture,
      image: "/images/service_icon/3.jpg",
    },
    {
      title: t("offer.list.3.title"),
      desc: t("offer.list.3.desc"),
      value: "Zentangle",
      Icon: Icon.Camera,
      image: "/images/service_icon/2.jpg",
    },

    {
      title: t("offer.list.4.title"),
      desc: t("offer.list.4.desc"),
      value: "Yoga & Stretching",
      Icon: Icon.Compass,
      image: "/images/service_icon/4.jpg",
    },
    {
      title: t("offer.list.5.title"),
      desc: t("offer.list.5.desc"),
      value: "Solution-Focused Training",
      Icon: Icon.Compass,
      image: "/images/service_icon/5.jpg",
    },
  ];
  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const onClick = (item) => {
    switch (item.value) {
      case "Counseling":
        setCounselingPopup(true);
        break;
      case "Premarital or Postmarital Counseling":
        setPremaritalCounselingPopup(true);
        break;
      case "Zentangle":
        setZentanglePopup(true);
        break;
      case "Yoga & Stretching":
        setYogaStretchingPopup(true);
        break;
      case "Solution-Focused Training":
        setSftPopup(true);
        break;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrevious = () => {
    // Handle logic for previous button
    const previousSlide = activeSlide - 1;
    if (previousSlide >= 0) {
      handleSlideChange(previousSlide);
    } else {
      handleSlideChange(servicesData.length - 1);
    }
  };

  const handleNext = () => {
    const nextSlide = activeSlide + 1;
    if (nextSlide < servicesData.length) {
      handleSlideChange(nextSlide);
    } else {
      handleSlideChange(0);
    }
  };
  return (
    <>
      <section
        className="relative md:py-24 py-16 bg-green-400 dark:bg-slate-800"
        id="service"
      >
        <div className="brush_service_5" data-wow-duration="1s">
          <img src="/images/bg/brushes/service/5.png" alt="1" />
        </div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h4
              className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
                i18n.resolvedLanguage === "zh"
                  ? "font-reborn  "
                  : "font-semibold"
              } `}
            >
              {t("offer.title")}
            </h4>

            {/* <p
              className={`text-slate-400 max-w-xl mx-auto text-[15px]  ${
                i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
              } `}
            >
              {t("offer.p1")}
            </p> */}
          </div>
          {/* ======== Mobile mode ======== */}
          {isMobile && (
            <div className="grid grid-cols-1 items-center gap-[10px]">
              <div className="relative">
                <Carousel
                  selectedItem={activeSlide}
                  onChange={handleSlideChange}
                  showStatus={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={5000}
                  transitionTime={1500}
                  showArrows={false}
                  showIndicators={false}
                  className="w-full mx-auto rounded-lg "
                >
                  {servicesData.map((item, index) => {
                    return (
                      <div
                        className=" px-6 py-10  shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900"
                        key={index}
                        onClick={() => onClick(item)}
                        style={{ margin: "0 3px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={item.image}
                            className="max-w-xs h-250 rounded-lg"
                            alt=""
                          />
                        </div>
                        <div className="content mt-7">
                          {/* <div
                            className={`title h5 text-[17px] hover:text-amber-500 ${
                              i18n.resolvedLanguage === "zh"
                                ? "font-reborn"
                                : "font-medium"
                            } `}
                          >
                            {item.title}
                          </div> */}

                          <h4
                            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
                              i18n.resolvedLanguage === "zh"
                                ? "font-reborn  "
                                : "font-medium"
                            } `}
                          >
                            {item.title}
                          </h4>
                          <p
                            className={`text-slate-400 mt-3 text-[15px] ${
                              i18n.resolvedLanguage === "zh"
                                ? "font-reborn  "
                                : ""
                            } `}
                          >
                            {isMobile &&
                              item.desc.split(" ").slice(0, 15).join(" ") +
                                " ..."}
                            {!isMobile && item.desc}
                          </p>

                          <div className="mt-5">
                            <div
                              className={`hover:text-amber-500 dark:hover:text-amber-500 after:bg-amber-500 dark:text-white transition duration-500 inline-flex items-center gap-1 ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn "
                                  : ""
                              }`}
                            >
                              {t("offer.read_more")}
                              <Unicons.UilArrowRight width={16} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>

              <div className="z-3 mt-3 flex justify-center space-x-1 dark:text-gray-100">
                <button
                  title="previous"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md dark:bg-gray-900 dark:border-gray-800"
                  onClick={handlePrevious}
                >
                  <svg
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`w-4 ${
                      i18n.resolvedLanguage === "zh" ? "font-reborn " : ""
                    } `}
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </button>
                {servicesData.map((item, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      title={`Page ${index + 1}`}
                      className={`inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border rounded shadow-md dark:bg-gray-900 ${
                        index === activeSlide ? "text-amber-500" : "bg-gray-300"
                      }
                      ${i18n.resolvedLanguage === "zh" ? "font-reborn " : ""}
                      `}
                      onClick={() => handleSlideChange(index)}
                    >
                      {index + 1}
                    </button>
                  );
                })}
                <button
                  title="next"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md dark:bg-gray-900 dark:border-gray-800"
                  onClick={handleNext}
                >
                  <svg
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`w-4 ${
                      i18n.resolvedLanguage === "zh" ? "font-reborn " : ""
                    } `}
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </button>
              </div>
            </div>
          )}
          {/* ======== Desktop mode ======== */}
          {!isMobile && (
            <div className="grid grid-cols-2 items-center gap-[30px]">
              <div className="lg:col-span-2 ">
                <div className="relative">
                  <Carousel
                    selectedItem={activeSlide}
                    onChange={handleSlideChange}
                    showStatus={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                    transitionTime={1500}
                    showArrows={false}
                    showIndicators={false}
                    className="w-full mx-auto rounded-lg "
                  >
                    {servicesData.map((item, index) => {
                      return (
                        <div
                          className=" px-6 py-10  shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900"
                          key={index}
                          onClick={() => onClick(item)}
                          style={{ margin: "0 3px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={item.image}
                              className="max-w-xs h-250 rounded-lg"
                              alt=""
                            />
                          </div>
                          <div className="content mt-7">
                            <h4
                              className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn  "
                                  : "font-semibold"
                              } `}
                            >
                              {item.title}
                            </h4>

                            <p
                              className={`text-slate-400 mt-3 text-[15px] ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn "
                                  : ""
                              }`}
                            >
                              {isMobile &&
                                item.desc.split(" ").slice(0, 15).join(" ") +
                                  " ..."}
                              {!isMobile && item.desc}
                            </p>

                            <div className="mt-5">
                              <div
                                className={`hover:text-amber-500 dark:hover:text-amber-500 after:bg-amber-500 dark:text-white transition duration-500 inline-flex items-center gap-1
                              ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn text-[15px]"
                                  : ""
                              }
                              `}
                              >
                                {t("offer.read_more")}
                                <Unicons.UilArrowRight width={16} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>

              <div className="lg:col-span-10">
                <div className="lg:ms-5">
                  <div className="w-96">
                    {servicesData.map((item, index) => {
                      return (
                        <div
                          className="bg-white mb-4 flex items-center rounded-lg shadow-md overflow-hidden max-w-xl relative dark:bg-gray-900 dark:text-gray-100"
                          key={index}
                          onClick={() => handleSlideChange(index)}
                        >
                          <div className="bg-warning self-stretch flex items-center px-1 flex-shrink-0 " />
                          <div className="p-4 flex-1">
                            <p
                              className={`title font-medium hover:text-amber-500 cursor-pointer ${
                                index === activeSlide
                                  ? "text-amber-500"
                                  : "bg-gray-300"
                              }
                              ${
                                i18n.resolvedLanguage === "zh"
                                  ? "font-reborn text-[32px] "
                                  : "h5 text-[17px]"
                              }
                              `}
                            >
                              {item.title}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Counseling
          // to={`${location.pathname}#service`}
          open={counselingPopup}
          close={() => setCounselingPopup(false)}
          setOpen={setCounselingPopup}
        />

        <Zentangle
          open={ZentanglePopup}
          close={() => setZentanglePopup(false)}
          setOpen={setZentanglePopup}
        />
        <PremaritalCounseling
          open={premaritalCounselingPopup}
          close={() => setPremaritalCounselingPopup(false)}
          setOpen={setPremaritalCounselingPopup}
        />
        <YogaStretching
          open={yogaStretchingPopup}
          close={() => setYogaStretchingPopup(false)}
          setOpen={setYogaStretchingPopup}
        />

        <SFT
          open={sftPopup}
          close={() => setSftPopup(false)}
          setOpen={setSftPopup}
        />
      </section>
    </>
  );
}
