// src/component/OnePost.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../components/Navbar";

import PhotoAlbum from "react-photo-album";

// hook
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  Typography,
  TabPanel,
} from "@material-tailwind/react";

const builder = imageUrlBuilder(sanityClient);
const navbar = document.getElementById("navbar");

navbar?.classList.add("is-sticky");
function urlFor(source) {
  return builder.image(source);
}

// TODO Add animation
// TODO Change Navbar

//  Function to filter photos array based on tag
const filterPhotosByTag = (photos, tag) => {
  return tag === "All" ? photos : photos.filter((photo) => photo.tag === tag);
};

export default function Album() {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = React.useState("All");
  const [photoData, setPhotoData] = useState(null);
  const [selectedTag, setSelectedTag] = useState("All");
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const data = [
    {
      label: t("project.label.1"),
      value: "Solution-Focused Training",
      images: [],
    },
    {
      label: t("project.label.2"),
      value: "Zentangle",
      images: [],
    },
    {
      label: t("project.label.3"),
      value: "Yoga & Stretching",
      images: [],
    },
    {
      label: t("project.label.4"),
      value: "All",
      images: [],
    },
  ];
  const { slug } = useParams();
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const postData = await sanityClient.fetch(
          `*[_type=="album"]{
        name,
  
        tag[]-> {
            name
        },
        image {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
        );

        const formattedPhotos = postData.map((data) => ({
          src: data.image.asset.url,
          width: 150,
          height: 100,
          tag: data.tag[0]?.name.current || "",
        }));
        // console.log(formattedPhotos);
        setPhotoData(formattedPhotos);
        setFilteredPhotos(formattedPhotos);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchPostData();
  }, [slug]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
    const newFilteredPhotos = filterPhotosByTag(photoData, tag);
    setFilteredPhotos(newFilteredPhotos);

    // console.log(newFilteredPhotos);
  };

  if (!photoData)
    return (
      <>
        <Navbar />

        <section className=" bg-green-400 h-screen w-full" id="album">
          <div className="fixed top-0 h-screen w-full bg-opacity-70 backdrop-blur-sm flex flex-col justify-center items-center z-50">
            <div className="spinner">
              <div
                className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </section>
      </>
    );

  return (
    <div className="overflow-y-auto  hide-scrollbar">
      <Navbar />

      <div
        className={`bg-green-400 ${isMobile ? "min-h-screen" : "h-screen"} `}
      >
        <section className=" bg-green-400 relative md:py-16 py-16" id="album">
          <div className="mt-6 container my-24 mx-auto md:px-6">
            <h3
              className={`mb-6 mt-12 md:text-2xl text-xl md:leading-normal leading-normal text-center
            ${i18n.resolvedLanguage === "zh" ? "font-reborn" : "font-semibold"}
            `}
            >
              {t("navBar.album")}
            </h3>

            <Tabs value={activeTab}>
              <TabsHeader
                className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                }}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => {
                      handleTagChange(value);
                      setActiveTab(value);
                    }}
                    className={
                      activeTab === value ? "text-amber-500" : "text-slate-400"
                    }
                  >
                    <p
                      className={`
                       ${
                         i18n.resolvedLanguage === "zh"
                           ? " font-reborn "
                           : "font-medium"
                       }
                      
                      `}
                    >
                      {label}
                    </p>
                    {/* <Typography
                      variant="small"
                      className={`
                      ${
                        i18n.resolvedLanguage === "zh"
                          ? "font-reborn"
                          : "font-medium"
                      }
                    `}
                    >
                      {label}
                    </Typography> */}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody className="mt-3 grid grid-cols-1 gap-4 h-screen ">
                <PhotoAlbum layout="rows" photos={filteredPhotos} />
              </TabsBody>
            </Tabs>
          </div>
        </section>
      </div>
    </div>
  );
}
