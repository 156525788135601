import React, { memo } from "react";

import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import SFTTestimonial from "../testimonials/SFTTestimonial";
import { Typography, Button } from "@material-tailwind/react";
// hook
import { useTranslation, Trans } from "react-i18next";

const Counseling = memo(({ open, close, setOpen }) => {
  const { t, i18n } = useTranslation();

  const count = 5;
  return (
    <>
      <TEModal show={open} setShow={setOpen} scrollable>
        <TEModalDialog size="xl">
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5
                className={`leading-normal text-neutral-800 dark:text-neutral-200
                ${
                  i18n.resolvedLanguage === "zh"
                    ? "font-reborn ml-3 text-2xl"
                    : "text-xl font-medium"
                }
              
              `}
              >
                {t("popUp.sft.title")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => close(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            {/* <TEModalBody style={{ minHeight: "1500px" }}> */}

            <TEModalBody
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <div className="popContainer">
                <div
                  className="mb-3 relative overflow-hidden"
                  style={{ paddingTop: "56.25%", width: "100%" }}
                >
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src="https://www.youtube.com/embed/5fhncsBjGgI?si=oN_D50GBbLaAfI8S"
                    title="Embedded Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="justify-center p-20">
                  <ul>
                    <li key="stf11">
                      <Trans i18nKey="popUp.sft.p1"></Trans>
                    </li>
                  </ul>

                  <ul className="mt-3">
                    <li></li>
                    <li key="sft12">{t("popUp.sft.p2")}</li>
                    <li key="sft13">{t("popUp.sft.p3")}</li>
                    <li key="sft14">{t("popUp.sft.p4")}</li>
                  </ul>
                </div>
                <SFTTestimonial />
              </div>
            </TEModalBody>
            <TEModalFooter
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <TERipple rippleColor="light">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border  px-4 rounded font-reborn"
                  onClick={() => close(false)}
                >
                  <Typography className="text-gray-600 font-reborn text-sm">
                    {t("popUp.counseling.close")}
                  </Typography>
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
});

export default Counseling;
