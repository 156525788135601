import React, { memo } from "react";
import * as Unicons from "@iconscout/react-unicons";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { Typography, Button } from "@material-tailwind/react";
// hook
import { useTranslation, Trans } from "react-i18next";
import ZentangleTestimonial from "../testimonials/ZentangleTestimonial";
// component
import { SocialIcon } from "react-social-icons/component";

// Accessing environment variables using process.env
const chp_ig_link = process.env.REACT_APP_ZENTANGLE_IG;
const chp_facebook_link = process.env.REACT_APP_ZENTANGLE_FACEBOOK;

const Zentangle = memo(({ open, close, setOpen }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <TEModal show={open} setShow={setOpen} scrollable>
        <TEModalDialog size="xl">
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5
                className={` leading-normal text-neutral-800 dark:text-neutral-200
             ${
               i18n.resolvedLanguage === "zh"
                 ? "font-reborn ml-3 text-2xl"
                 : "text-xl font-medium"
             }
              `}
              >
                {t("popUp.zentangle.title")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => close(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            {/* <TEModalBody style={{ minHeight: "1500px" }}> */}

            <TEModalBody
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <div className="popContainer">
                <div className="mb-4">
                  {/* 1500 x 350 */}
                  <img
                    src="/images/service/zentangle.jpg"
                    className="w-[1500] h-[350] rounded-lg mt-4"
                    alt=""
                  />
                </div>
                <ul>
                  <li key="z0" style={{ display: "none" }}></li>
                  <li key="z1" style={{ marginBottom: "10px" }}>
                    {t("popUp.zentangle.p1")}
                  </li>
                  <li key="z2" style={{ marginBottom: "10px" }}>
                    <a
                      href={chp_ig_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-1"
                    >
                      <span className="login-btn-primary">
                        <span className=" mr-3 btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                          <Unicons.UilInstagram width={16} />
                        </span>
                      </span>
                      {process.env.REACT_APP_ZENTANGLE_IG_NAME}
                    </a>
                  </li>
                  <li key="z3">
                    <a
                      href={chp_facebook_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-1"
                    >
                      <span className="login-btn-primary ">
                        <span className="mr-3 btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                          <Unicons.UilFacebook width={16} />
                        </span>
                      </span>
                      {process.env.REACT_APP_ZENTANGLE_FACEBOOK_NAME}
                    </a>
                  </li>
                  <br />

                  <li key="p2">
                    {t("popUp.zentangle.1")} <br />
                    {t("popUp.zentangle.2")} <br />
                    {t("popUp.zentangle.3")} <br />
                    {t("popUp.zentangle.4")} <br />
                    {t("popUp.zentangle.5")} <br />
                    {t("popUp.zentangle.6")} <br />
                  </li>

                  <p className="mt-3">
                    {t("popUp.zentangle.7")} <br />
                    {t("popUp.zentangle.8")} <br />
                    {t("popUp.zentangle.9")} <br />
                    {t("popUp.zentangle.10")} <br />
                  </p>
                </ul>

                <ZentangleTestimonial />
              </div>
            </TEModalBody>
            <TEModalFooter
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <TERipple rippleColor="light">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border  px-4 rounded font-reborn"
                  onClick={() => close(false)}
                >
                  <Typography className="text-gray-600 font-reborn text-sm">
                    {t("popUp.counseling.close")}
                  </Typography>
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
});

export default Zentangle;
