// src/component/OnePost.js

import React, { useEffect, useState } from "react";

import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../../components/Navbar.js";

import K10Form from "./K10Form";

// hook
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  List,
  ListItem,
  Card,
  Typography,
  Button,
  CardHeader,
  CardBody,
  Input,
  Avatar,
} from "@material-tailwind/react";

const imgs = [
  "https://www.material-tailwind.com/image/web3-card-1.svg",
  "https://www.material-tailwind.com/image/web3-card-2.svg",
  "https://www.material-tailwind.com/image/web3-card-3.svg",
];

const builder = imageUrlBuilder(sanityClient);
const navbar = document.getElementById("navbar");

navbar?.classList.add("is-sticky");
function urlFor(source) {
  return builder.image(source);
}

//  Function to filter photos array based on tag

export default function PsychologicalDistressTest() {
  const [isMobile, setIsMobile] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleStartClick = () => {
    setShowForm(true);
  };

  return (
    <>
      <Navbar />

      <section
        className="
        relative pt-25 overflow-hidden flex items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('/images/slider/1.png')",
          height: "100vh",
        }}
        id="self-test"
      >
        <div className=" container-self-test  px-4 ">
          <div className="flex justify-center items-center ">
            <div className="">
              {/* ======================================= */}
              <Card
                shadow={false}
                className=" overflow-hidden border border-gray-300 p-6 bg-white rounded-lg self-test-card"
              >
                <CardHeader
                  shadow={false}
                  className="text-center self-test-cardHeader"
                >
                  <Typography
                    variant="h1"
                    color="blue-gray"
                    className="text-2xl md:text-3xl font-bold"
                  >
                    凱斯勒心理困擾量表 (K10)
                  </Typography>
                </CardHeader>

                <CardBody>
                  {!showForm ? (
                    <form action="#" className="flex flex-col gap-4  md:mt-12">
                      <Typography className="text-gray-600 text-base md:text-lg font-light self-test-content">
                        這是一個評估你心理困擾程度的小測試。以下是一些詢問你情緒的問題。回答每一個問題時，請選擇最能描述這種情緒出現頻率的選項。
                      </Typography>
                      <div className="flex justify-center">
                        <Button
                          size="lg"
                          className="flex h-12 border-blue-gray-200 items-center justify-center gap-2 font-reborn bg-black text-white rounded-lg self-test-button"
                          onClick={handleStartClick}
                          variant="filled"
                        >
                          <Typography
                            variant="h6"
                            className="text-left mx-auto font-bold text-gray-600 w-[95vw]"
                          >
                            開始
                          </Typography>
                        </Button>
                      </div>

                      <Typography
                        variant="small"
                        className="text-left mx-auto max-w-[19rem] font-medium text-gray-600 text-xs self-test-content"
                      >
                        參考資料︰
                        <br />
                        Kessler, R.C., Barker, P.R., Colpe, L.J., Epstein, J.F.,
                        Gfroerer, J.C., Hiripi, E., Howes, M.J, Normand, S-L.T.,
                        Manderscheid, R.W., Walters, E.E., & Zaslavsky, A.M.
                        (2003). Screening for serious mental illness in the
                        general population. Archives of General Psychiatry,
                        60(2), 184-189.
                        <br />
                        {""}
                        Andrews, G., Slade, T. (2001). Interpreting scores on
                        the Kessler Psychological Distress Scale (K10).
                        Australian and New Zealand Journal of Public Health, 25,
                        494-497. Victorian Population Health Survey (2001).
                        Melbourne: Department of Human Services, Victoria.
                      </Typography>

                      <div className="border  border-gray-400 p-4 rounded-lg  self-test-content">
                        <Typography
                          variant="small"
                          className="text-center mx-auto max-w-[19rem] font-medium text-gray-600"
                        >
                          注意 : 凱斯勒心理困擾量表(K10)
                          只是一個篩查工具，其目的是讓我們概略了解自己的情緒狀況，評估結果僅供參考，並不能視為或等同由醫護專業人員所診斷的精神疾患。
                        </Typography>
                      </div>
                    </form>
                  ) : (
                    <K10Form />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
