import React, { useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import Counseling from "../../components/popup/Counseling.js";

const K10Form = () => {
  const questions = [
    "過去一個月，你有多少時候感到無法解釋的筋疲力盡?",
    "過去一個月，你有多少時候感到緊張？",
    "過去一個月，你有多少時候感到太緊張以至於甚麼都不能讓你平靜下來？",
    "過去一個月，你有多少時候感到絕望？",
    "過去一個月，你有多少時候感到不安或煩躁？",
    "過去一個月，你有多少時候感到太過不安以至於靜坐不能？",
    "過去一個月，你有多少時候感到沮喪？",
    "過去一個月，你有多少時候感到太沮喪以至於什麼都不能讓你愉快起來?",
    "過去一個月，你有多少時候感到做每一件事情都很費勁？",
    "過去一個月，你有多少時候感到自己無價值？",
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showHelpComponent, setShowHelpComponent] = useState(false);

  const handleAnswer = (value) => {
    console.log(`Question ${currentQuestionIndex + 1} answer: ${value}`);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTotalScore(totalScore + value);
    } else {
      setTotalScore(totalScore + value);
      setIsSubmitted(true);
      console.log("Form submitted with total score:", totalScore + value);
    }
  };

  const handleShowHelp = () => {
    setShowHelpComponent(true);
  };

  return (
    <div className="flex flex-col gap-4 md:mt-12 ">
      {!isSubmitted ? (
        <form className="">
          <Typography className="!text-gray-600 text-[18px] font-reborn self-test-question">
            {currentQuestionIndex + 1}. {questions[currentQuestionIndex]}
          </Typography>

          <div className="flex flex-col gap-2 mt-3 self-test-answer">
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(5)}
            >
              全部時間
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(4)}
            >
              大部分時間
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn "
              onClick={() => handleAnswer(3)}
            >
              一部分時間
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(2)}
            >
              偶爾
            </Button>
            <Button
              variant="outlined"
              size="lg"
              className="border p-2 rounded font-reborn"
              onClick={() => handleAnswer(1)}
            >
              無
            </Button>
            <Typography className="mt-3 font-reborn !text-gray-600 md:max-w-sm flex-shrink-0 mx-auto text-center">
              {currentQuestionIndex + 1} / {questions.length}
            </Typography>
          </div>
          {/* </div> */}
        </form>
      ) : (
        <>
          <div className="flex justify-center">
            <p className="totalScore">{totalScore}</p>
          </div>
          <div className="flex flex-col items-start">
            <div className="flex flex-col gap-2 ">
              <Typography
                className={`text-[18px] text-gray-600 font-reborn ${
                  totalScore <= 19 ? "font-bold" : ""
                }`}
              >
                19或以下: 你的心理狀態好像是不錯
              </Typography>
              <Typography
                className={`text-[18px] text-gray-600 font-reborn ${
                  totalScore >= 20 && totalScore <= 24 ? "font-bold" : ""
                }`}
              >
                20-24: 你可能有輕度的情緒困擾
              </Typography>
              <Typography
                className={`text-[18px] text-gray-600 font-reborn ${
                  totalScore >= 25 && totalScore <= 29 ? "font-bold" : ""
                }`}
              >
                25-29: 你可能有中等程度的情緒困擾
              </Typography>
              <Typography
                className={`text-[18px] text-gray-600 font-reborn ${
                  totalScore >= 30 ? "font-bold" : ""
                }`}
              >
                30或以上: 你可能有嚴重的情緒困擾
              </Typography>
              <div className="flex flex-col items-start mt-4">
                <Typography className="text-[18px] text-gray-600 font-reborn">
                  如有懷疑或需要，請點擊
                  <span className="click-here " onClick={handleShowHelp}>
                    這裏
                  </span>
                  查看可以從哪些地方得到幫助。
                </Typography>
                <Counseling />
                {showHelpComponent && (
                  <Counseling
                    open={showHelpComponent}
                    close={() => setShowHelpComponent(false)}
                    setOpen={setShowHelpComponent}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default K10Form;
