import React, { memo } from "react";

import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { Typography, Button } from "@material-tailwind/react";
// hook
import { useTranslation, Trans } from "react-i18next";
import PremaritalCounselingTestimonial from "../testimonials/PremaritalCounselingTestimonial";

const PremaritalCounseling = memo(({ open, close, setOpen }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <TEModal show={open} setShow={setOpen} scrollable>
        <TEModalDialog size="xl">
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5
                className={` leading-normal text-neutral-800 dark:text-neutral-200
             ${
               i18n.resolvedLanguage === "zh"
                 ? "font-reborn ml-3 text-2xl"
                 : "text-xl font-medium"
             }
              `}
              >
                {t("popUp.premarital-counseling.title")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => close(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <div className="popContainer">
                <div
                  className="mb-3 relative overflow-hidden"
                  style={{ paddingTop: "56.25%", width: "100%" }}
                >
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src="https://www.youtube.com/embed/ozEUijIYDQs?si=fvjKzkFvaz-MpuN0"
                    title="Embedded Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

                <ul>
                  <li key="p1" className="mb-3">
                    <Trans i18nKey="popUp.premarital-counseling.p1">
                      Using <strong>“Prepare/Enrich Inventory”</strong>, which
                      is the premarital or marital relationship assessment tool,
                      as a foundation to prepare both parties ready to move
                      towards the next stage of life together or continue to
                      carry on the marriage
                    </Trans>
                  </li>

                  <li key="p2">
                    {t("popUp.premarital-counseling.deal-with")}
                    <ul className="ml-6 list-disc">
                      <li style={{ display: "none" }}></li>
                      <li key="p3">{t("popUp.premarital-counseling.1")}</li>
                      <li key="p4">{t("popUp.premarital-counseling.2")}</li>
                      <li key="p5">{t("popUp.premarital-counseling.3")}</li>
                      <li key="p6">{t("popUp.premarital-counseling.4")} </li>
                      <li key="p7">{t("popUp.premarital-counseling.5")}</li>
                      {/* <p>
                        <Trans i18nKey="popUp.premarital-counseling.6">
                          * Premarital counseling is suggested to start the
                          counseling <strong>9 months</strong> before marriage
                          OR stable relationship considering to the next stage
                        </Trans>
                      </p> */}
                    </ul>
                  </li>
                </ul>

                <ul className="mt-3">
                  <li></li>
                  <li key="p9">{t("popUp.premarital-counseling.7")}</li>
                  <li key="y10">{t("popUp.premarital-counseling.8")}</li>
                  <li key="y11">{t("popUp.premarital-counseling.9")}</li>
                  <li key="y13">
                    {t("popUp.premarital-counseling.11")}
                    {t("popUp.premarital-counseling.12")}
                  </li>
                  <li key="y14"></li>
                  <div className="ml-8">
                    <li></li>
                    <li key="y15">{t("popUp.premarital-counseling.13")}</li>
                    <li key="y16">{t("popUp.premarital-counseling.14")}</li>
                    <li key="y17">{t("popUp.premarital-counseling.15")}</li>
                  </div>

                  <li key="y12" className="mt-3">
                    {t("popUp.premarital-counseling.10")}
                  </li>
                </ul>

                <PremaritalCounselingTestimonial />
              </div>
            </TEModalBody>
            <TEModalFooter
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <TERipple rippleColor="light">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border  px-4 rounded font-reborn"
                  onClick={() => close(false)}
                >
                  <Typography className="text-gray-600 font-reborn text-sm">
                    {t("popUp.counseling.close")}
                  </Typography>
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
});

export default PremaritalCounseling;
